<template>
  <div>
    <v-card :disabled="isProcessing" :loading="isProcessing">
      <template v-slot:progress>
        <v-progress-linear
          absolute
          color="success"
          height="4"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-tabs v-model="tab" class="deep-gl" left dark icons-and-text>
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1">
          <v-toolbar-title>
            {{ name }}
          </v-toolbar-title>
        </v-tab>
        <v-spacer></v-spacer>

        <div class="buttons-tab"></div>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-card flat>
            <template v-slot:progress>
              <v-progress-linear
                absolute
                color="success"
                height="4"
                indeterminate
              ></v-progress-linear>
            </template>
            <v-card-text>
              <v-form
                @submit.prevent
                ref="requestData"
                v-model="validRequestData"
              >
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="requestData.min_price"
                      label="Min price"
                      required
                      :rules="[validations.required()]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="requestData.add_price"
                      label="Add price"
                      :rules="[validations.required()]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="requestData.percentage"
                      label="Percentage"
                      :rules="[validations.required()]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                v-on:click="store()"
                v-if="hasPermissions('price-config-create')"
                >{{ type }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import validations from "@/views/partials/form/Validations.vue";
import {
  GET_PRICE_CONFIG,
  UPDATE_PRICE_CONFIG,
} from "@/store/price_config.module";
import { SHOW_SNACK } from "@/store/snack.module";

export default {
  name: "PriceConfig",
  components: {
  },
  data() {
    return {
      name: "Price Config",
      validations: validations,
      validRequestData: false,
      tab: "tab-1",
      isProcessing: false,
      requestData: {},
      type: "Update"
    };
  },
  computed: {},
  mounted() {
    this.selectPriceConfig();
  },
  methods: {
    store() {
      this.$refs.requestData.validate();

      if (!this.validRequestData) {
        return;
      }

      let url = UPDATE_PRICE_CONFIG;
      let params = { params: this.requestData };

      this.isProcessing = true;
      this.$store
        .dispatch(url, params)
        .then(() => {
          this.$store.dispatch(SHOW_SNACK, {
            message: "Price config stored successfuly.",
          });
        })
        .catch((error) => {})
        .finally(() => {
          this.isProcessing = false;
        });
    },
    selectPriceConfig() {
      this.isProcessing = true;
      this.$store
        .dispatch(GET_PRICE_CONFIG)
        .then((response) => {
          this.requestData = response;
        })
        .catch((error) => {

        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

  },
};
</script>